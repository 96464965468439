<template>
    <div>
        <v-data-table
            :items="products"
            :headers="[
                { text: 'Product Name', align: 'left', value: 'name', sortable: true},
                { text: 'Company', align: 'left', value: 'company.name', sortable: true},
                { text: 'Company Phone', align: 'left', value: 'company.phone', sortable: false},
                { text: 'Company Location', align: 'left', value: 'company.location', sortable: false},
                { text: 'Product Category', align: 'left', value: 'category', sortable: false},
                { text: 'Quantity', align: 'left', value: 'quantity', sortable: false},
                { text: 'Weight', align: 'left', value: 'weight', sortable: false},
                { text: 'Price',  align: 'left', value: 'price', sortable: false},
            ]"
        >
            <template v-slot:top>
                <v-toolbar color="primary" style=" border-radius : 7px" class="white--text mb-3" >
                    <v-btn icon dark @click="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>    
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-toolbar-title>
                        <span class=" text-capitalize">Customer Products</span>
                        <br/>
                        <span
                            class="subheading font-weight-bold caption"
                        >
                            List of customer purchases
                        </span>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="openLogistics" 
                        rounded text 
                        :disabled="!marketRequest.id"
                        dark v-if="userData.account_type == 'admin'">
                        <v-icon left>mdi-truck-delivery</v-icon> Assign Logistics
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:item.price="{ item }">
                <span>{{item.price | currency}}</span>            
            </template>


            <template v-slot:footer>
                <v-row justify="end" style="border-radius: 7px" class="primary elevation-7 py-5 white--text">
                    <div class="mr-5  text-right ">
                        <div>Total weight {{totalWeight}} kg</div>
                    </div>
                    <div class="mr-7 text-right">
                        <div>Total Amount {{total | currency}}</div>
                    </div>
                </v-row>
            </template>
        </v-data-table>
        <v-dialog
            v-model="logisticsSelectionDialog"
            width="500"
        >
        <v-card>
            <v-card-title
            class="headline primary white--text"
            primary-title
            >
                Assign a logistics partner
                <v-spacer/>
                <v-btn icon text dark @click="logisticsSelectionDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-alert v-show="error" color="error">Error assigning logistics partner</v-alert>
            <v-card-text>
                <v-row justify="center"  v-if="logisticsSelectionDialogLoader" >
                    <v-progress-circular :size="48" indeterminate></v-progress-circular>
                </v-row>
                <v-simple-table v-else>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Weight</th>
                            <th class="text-left">Location</th>
                            <th class="text-left">Assign</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr v-for="item in logisticsPartners" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td>
                                    <span v-for="(capacity, index) in item.capacity" :key="index">
                                        {{capacity.name}}.
                                    </span>
                                </td>
                                <td>{{ item.location }}</td>
                                <td>
                                    <v-btn
                                        :loading="assigningLogisticsLoader === item.id" 
                                        :disabled="assigningLogisticsLoader ? true : false" 
                                        @click="assignLogisticPartner(item.id)" 
                                        color="primary">Assign
                                    </v-btn>
                                </td>
                            </tr>
                    </tbody>
                </template>
                </v-simple-table>    
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return {
            products: [],
            logisticsPartners: [],
            selectedLogistic: '',
            error: '',
            assigningLogisticsLoader: false,
            logisticsSelectionDialog: false,
            logisticsSelectionDialogLoader: false
        }
    },
    computed:{
        totalWeight(){
            let totalWeight = 0
            this.products.forEach(item => {
                totalWeight += ( item.quantity * (item.weight ? item.weight : 0))
            })
            return totalWeight
        },
        total(){
            let total = 0
            this.products.forEach(product=>{
                total += product.price  * product.quantity
            })
            return total
        }
    },
    methods:{
        openLogistics(){
            this.logisticsSelectionDialog = true
            this.logisticsSelectionDialogLoader = true
            this.$apollo.query({
                query: require('../../../legacy/unwanted_gql/logistics.glq').get,
                variables: {

                },
            })
            .then(res=>{
                this.logisticsPartners = res.data.logistics
                this.logisticsSelectionDialogLoader = false
            })
            .catch(err=>{
                console.error(err)
            })
        },
        assignLogisticPartner(logistics_id){
            this.assigningLogisticsLoader = logistics_id
            this.error = ''
            this.$apollo.mutate({
                mutation: require('../../gql/marketRequest.gql').update,
                variables:{
                    where: {
                        id: this.marketRequest.id
                    },
                    data: {
                        logistics: {
                            connect: {
                                id : logistics_id
                            }
                        }
                    }
                }
            }).then(_=>{
                this.logisticsSelectionDialog = false
            }).catch(err=>{
                console.error(err.message)
                this.error = err.message
            }).finally(_=>{
                this.assigningLogisticsLoader = false
            })
        }
    },
    created(){
        this.marketRequest = this.$route.params.self
        let products = this.marketRequest.products
        if(products){
            console.log(products)
            this.products = products.map( item=>({
                company: {
                    name: item.product.company.name,
                    phone: item.product.company.phone,
                    location: item.product.company.location
                },
                name: item.product.name,
                category: item.product.category.name,
                quantity: item.qty,
                price: item.product.price,
                weight: item.product.weight
            }))
        }

    }
}
</script>