import {gql} from 'apollo-boost'

const fragment = `{
    name
    id
    phone_no
    capacity{
        id
        name
    }
    location
    tracking
    status
    type
    insurance
    user{
        id
    }
}
`

export const create = gql`
    mutation createLogistic($data: LogisticCreateInput!){
        createLogistic(data: $data)${fragment}
}
`

export const get = gql`
    query logistics($where: LogisticWhereInput){
    logistics(where: $where)${fragment}
}
`

export const update = gql`
    mutation updateLogistic($where: LogisticWhereUniqueInput! $data: LogisticUpdateInput!){
        updateLogistic(where: $where, data: $data)${fragment}
    }
`

export const remove = gql`
    mutation deleteLogistic($where: LogisticWhereUniqueInput!){
        deleteLogistic(where: $where){
            id
        }
    }
`

